
import waitForElementTransition from 'wait-for-element-transition';

// import switchx from "./assets/js/switch";
// import fieldset from "./assets/js/fieldset";

// Sidebar

let minimizeSidebar = async () => {
  let sidebar = document.querySelector(".sidebar");

  sidebar.classList.remove("sidebar--maximized");
  sidebar.classList.add("sidebar--minimizing");

  await waitForElementTransition(sidebar);

  sidebar.classList.remove("sidebar--minimizing");
  sidebar.classList.add("sidebar--minimizing-2");

  await waitForElementTransition(sidebar);

  sidebar.classList.remove("sidebar--minimizing-2");
  sidebar.classList.add("sidebar--minimized");
}

let maximizeSidebar = async () => {
  let sidebar = document.querySelector(".sidebar");

  sidebar.classList.remove("sidebar--minimized");
  sidebar.classList.add("sidebar--maximizing");

  await waitForElementTransition(sidebar);

  sidebar.classList.remove("sidebar--maximizing");
  sidebar.classList.add("sidebar--maximizing-2");

  await waitForElementTransition(sidebar);

  sidebar.classList.remove("sidebar--maximizing-2");
  sidebar.classList.add("sidebar--maximized");
}

let isSidebarMinimized = () => {
  let sidebar = document.querySelector(".sidebar");

  // Initially the sidebar has neither sidebar--minimized nor sidebar--maximized but is maximized. (for animation purposes)
  // if it contains sidebar--minimized we know for sure that it's minimized.
  return sidebar.classList.contains("sidebar--minimized") ? true : false;
}

window.toggleSidebar = () => isSidebarMinimized() ? maximizeSidebar() : minimizeSidebar();

let initializeProperty = (element) => {
  if (!element) throw "Element undefined";

  // For the maxHeight transition to work we need to know the height of the element.
  let propertyBody = element.querySelector(".property__body");
  if (!propertyBody) throw "Could not find property body";

  propertyBody.style.maxHeight = `${propertyBody.scrollHeight}px`;

  // Add toggle event to header
  let propertyHeader = element.querySelector(".property__header");
  if (!propertyHeader) throw "Could not find property header";

  propertyHeader.addEventListener("click", () => {
    // Toggle open-closed only if is not minified
    if (element.classList.contains)
    element.classList.contains("property--closed")
      ? element.classList.remove("property--closed")
      : element.classList.add("property--closed");
  });
};

for (const element of document.querySelectorAll(".property")) initializeProperty(element)