import { h } from 'preact';

import classNames from 'classnames';

// Icons
import switchIcon from "../../icons/checkbox_active.svg";

const Switch = ({
  firstValue,
  secondValue,
  checked, // false or true indicating firstValue and secondValue respectibly.
  disabled,
  onClick,
  onChange
}) => {
  // Prop validation
  if (!firstValue || !secondValue)
    throw "firstValue and/or secondValue not set";

  return (
    <label
      class={classNames(
        "switch",
        {"switch--disabled": disabled},
      )}
    >
      <input
        type="checkbox"
        class="switch__checkbox"
        checked={checked}
        onClick={onClick}
        onChange={onChange}
      />
      <div class="switch__graphic">
        <div class="switch__slide">
          <img class="switch__icon" src={switchIcon}/>
        </div>
      </div>
      <div class="switch__entries">
        <span>{firstValue}</span>
        <span>{secondValue}</span>
      </div>
    </label>
  )
}

export default Switch