const layerOptions = [
    // RGBs
    {
        name: "RGB_2019_q1",
        property: "SENTINEL",
        mode: "RGB",
        season: "SPRING",
        year: "2019",
    },
    {
        name: "RGB_2018_q2",
        property: "SENTINEL",
        mode: "RGB",
        season: "SUMMER",
        year: "2018",
    },
    {
        name: "RGB_2019_q2",
        property: "SENTINEL",
        mode: "RGB",
        season: "SUMMER",
        year: "2019",
    },
    {
        name: "RGB_2018_q3",
        property: "SENTINEL",
        mode: "RGB",
        season: "AUTUMN",
        year: "2018",
    },
    {
        name: "RGB_2019_q3",
        property: "SENTINEL",
        mode: "RGB",
        season: "AUTUMN",
        year: "2019",
    },
    {
        name: "RGB_2018_q4",
        property: "SENTINEL",
        mode: "RGB",
        season: "WINTER",
        year: "2018",
    },
    {
        name: "RGB_2019_q4",
        property: "SENTINEL",
        mode: "RGB",
        season: "WINTER",
        year: "2019",
    },

    // Nirs
    {
        name: "s2l2a_B08_2019_q1_P25",
        property: "SENTINEL",
        mode: "NIR",
        season: "SPRING",
        year: "2019",
    },
    {
        name: "s2l2a_B08_2018_q2_P25",
        property: "SENTINEL",
        mode: "NIR",
        season: "SUMMER",
        year: "2018",
    },
    {
        name: "s2l2a_B08_2019_q2_P25",
        property: "SENTINEL",
        mode: "NIR",
        season: "SUMMER",
        year: "2019",
    },
    {
        name: "s2l2a_B08_2018_q3_P25",
        property: "SENTINEL",
        mode: "NIR",
        season: "AUTUMN",
        year: "2018",
    },
    {
        name: "s2l2a_B08_2019_q3_P25",
        property: "SENTINEL",
        mode: "NIR",
        season: "AUTUMN",
        year: "2019",
    },
    {
        name: "s2l2a_B08_2018_q4_P25",
        property: "SENTINEL",
        mode: "NIR",
        season: "WINTER",
        year: "2018",
    },
    {
        name: "s2l2a_B08_2019_q4_P25",
        property: "SENTINEL",
        mode: "NIR",
        season: "WINTER",
        year: "2019",
    },
  {
      name: "clay_tot_psa_norm_v15",
      group: "clay_tot_psa_norm_v15",
      property: "PREDICTIONS",
      alias: "clay_tot_psa"
  },
  {
      name: "db_od_v15",
      group: "db_od_v15",
      property: "PREDICTIONS",
      alias: "db_od"
  },
  {
      name: "ph_h2o_v15",
      group: "ph_h2o_v15",
      property: "PREDICTIONS",
      alias: "ph_h2o"
  },
  {
      name: "oc_v16",
      group: "oc_v16",
      property: "PREDICTIONS",
      alias: "oc"
  },
  {
      name: "ph_kcl_v15",
      group: "ph_kcl_v15",
      property: "PREDICTIONS",
      alias: "ph_kcl"
  },
  {
      name: "sand_tot_psa_norm_v15",
      group: "sand_tot_psa_norm_v15",
      property: "PREDICTIONS",
      alias: "sand_tot_psa"
  },
  {
      name: "silt_tot_psa_norm_v15",
      group: "silt_tot_psa_norm_v15",
      property: "PREDICTIONS",
      alias: "silt_tot_psa"
  },
  // {
  //     name: "dbr_v15",
  //     property: "PREDICTIONS",
  //     alias: "dbr"
  // },
  {
      name: "k_mehlich3_v15",
      group: "k_mehlich3_v15",
      property: "PREDICTIONS",
      alias: "k_mehlich3"

  },
  {
      name: "p_mehlich3_v15",
      group: "p_mehlich3_v15",
      property: "PREDICTIONS",
      alias: "p_mehlich3"
  },
  {
      name: "ca_mehlich3_v15",
      group: "ca_mehlich3_v15",
      property: "PREDICTIONS",
      alias: "ca_mehlich3"
  },
  {
      name: "mg_mehlich3_v15",
      group: "mg_mehlich3_v15",
      property: "PREDICTIONS",
      alias: "mg_mehlich3"

  },
  {
      name: "caco3_v15",
      group: "caco3_v15",
      property: "PREDICTIONS",
      alias: "caco3"
  },
  {
      name: "wpg2_v15",
      group: "wpg2_v15",
      property: "PREDICTIONS",
      alias: "wpg2"
  },
  {
      name: "ec_satp_v15",
      group: "ec_satp_v15",
      property: "PREDICTIONS",
      alias: "ec_satp"
  },
  {
      name: "cec_sum_v15",
      group: "cec_sum_v15",
      property: "PREDICTIONS",
      alias: "cec_sum"
  },
  {
      name: "wrb_rsg_v15",
      group: "wrb_rsg_v15",
      property: "PREDICTIONS",
      alias: "wrb_rsg"
  },
  {
      name: "texture_v15",
      group: "texture_v15",
      property: "PREDICTIONS",
      alias: "texture_class"
  },
  {
      name: "clay_tot_psa_uncertainty_v15",
      group: "clay_tot_psa_norm_v15",
      property: "UNCERTAINTY",
      alias: "clay_tot_psa_uncertainty"
  },
  {
      name: "db_od_uncertainty_v15",
      group: "db_od_v15",
      property: "UNCERTAINTY",
      alias: "db_od_uncertainty"
  },
  {
      name: "ph_h2o_uncertainty_v15",
      group: "ph_h2o_v15",
      property: "UNCERTAINTY",
      alias: "ph_h2o_uncertainty"
  },
  {
      name: "oc_uncertainty_v16",
      group: "oc_v16",
      property: "UNCERTAINTY",
      alias: "oc_uncertainty"

  },
  {
      name: "ph_kcl_uncertainty_v15",
      group: "ph_kcl_v15",
      property: "UNCERTAINTY",
      alias: "ph_kcl_uncertainty"
  },
  {
      name: "sand_tot_psa_uncertainty_v15",
      group: "sand_tot_psa_norm_v15",
      property: "UNCERTAINTY",
      alias: "sand_tot_psa_uncertainty"
  },
  {
      name: "silt_tot_psa_uncertainty_v15",
      group: "silt_tot_psa_norm_v15",
      property: "UNCERTAINTY",
      alias: "silt_tot_psa_uncertainty"
  },
//   {
//       name: "dbr_uncertainty_v15",
//       property: "UNCERTAINTY",
//       alias: "dbr_uncertainty"

//   },
  {
      name: "k_mehlich3_uncertainty_v15",
      group: "k_mehlich3_v15",
      property: "UNCERTAINTY",
      alias: "k_mehlich3_uncertainty"
  },
  {
      name: "p_mehlich3_uncertainty_v15",
      group: "p_mehlich3_v15",
      property: "UNCERTAINTY",
      alias: "p_mehlich3_uncertainty"
  },
  {
      name: "ca_mehlich3_uncertainty_v15",
      group: "ca_mehlich3_v15",
      property: "UNCERTAINTY",
      alias: "ca_mehlich3_uncertainty"
  },
  {
      name: "mg_mehlich3_uncertainty_v15",
      group: "mg_mehlich3_v15",
      property: "UNCERTAINTY",
      alias: "mg_mehlich3_uncertainty"
  },
  {
      name: "caco3_uncertainty_v15",
      group: "caco3_v15",
      property: "UNCERTAINTY",
      alias: "caco3_uncertainty"
  },
  {
      name: "wpg2_uncertainty_v15",
      group: "wpg2_v15",
      property: "UNCERTAINTY",
      alias: "wpg2_uncertainty"
  },
  {
      name: "ec_satp_uncertainty_v15",
      group: "ec_satp_v15",
      property: "UNCERTAINTY",
      alias: "ec_satp_uncertainty"
  },
  {
      name: "cec_sum_uncertainty_v15",
      group: "cec_sum_v15",
      property: "UNCERTAINTY",
      alias: "cec_sum_uncertainty"
  },
  {
      name: "wrb_rsg_prediction_entropy_v15",
      group: "wrb_rsg_v15",
      property: "UNCERTAINTY",
      alias: "wrb_rsg_uncertainty"
  },
]

export default layerOptions;