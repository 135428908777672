import { h } from 'preact';
import { useEffect, useRef, useState} from "preact/hooks";

import classNames from 'classnames';

// Libraries
import {computePosition, offset, autoUpdate, arrow} from '@floating-ui/dom';

// Icons
import sentinelIcon from "../../icons/properties/sentinel.svg";
import plusIcon from "../../icons/plus.svg";
import minusIcon from "../../icons/minus.svg";

const Property = ({
  title,
  icon = sentinelIcon,
  open = false,
  children,
  active,
  floatingWindow
}) => {
  // Verify Props
  if (!title || !icon)
    throw "title and icon not set";
  
  // Load Refs
  const componentElement = useRef();
  const propertyHeaderElement = useRef();
  const propertyBodyElement = useRef();
  const floatingWindowElement = useRef();
  const floatingWindowArrowElement = useRef();

  // State
  const [isOpen, setOpen] = useState(open);
  const [floatingWindowVisible, setFloatingWindowVisible] = useState(false);

  // Functions
  const showFloatingWindow = () => {
    floatingWindowElement.current.style.visibility = "visible";
    floatingWindowElement.current.style.opacity = 1;
  }
  const hideFloatingWindow = () => {
    floatingWindowElement.current.style.visibility = "hidden";
    floatingWindowElement.current.style.opacity = 0;
  }
  const toggleFloatingWindow = () => {
    if (document.querySelector(".sidebar").classList.contains("sidebar--minimized")) {

      // Here we want to display small window with more info, on desktop this window appears on hover and on click
      if (
        floatingWindowElement.current.style.visibility === "hidden" &&
        componentElement.current.classList.contains("property--active")
      )
        showFloatingWindow();
      else
        hideFloatingWindow()
    }
  }

  // For the maxHeight transition to work we need to know the height of the element.
  // let propertyBody = element.querySelector(".property__body");
  const calculateBodyMaxHeight = () => {
    propertyBodyElement.current.style.maxHeight = `${propertyBodyElement.current.scrollHeight}px`;
  }

  const togglePropertyBody = () => {
    // Toggle open-closed only if is not minified
    if(!document.querySelector(".sidebar").classList.contains("sidebar--minimized")) {
      setOpen(!isOpen);

      // On mobile we could minimize all other besides the one that is getting maximized
    }
  }

  // Events
  const propertyHeaderOnClick = () => {
    toggleFloatingWindow();
    togglePropertyBody();
  }

  useEffect(() => {
    // Floating Window
    hideFloatingWindow();
    autoUpdate(propertyHeaderElement.current, floatingWindowElement.current, () => {
      computePosition(
        propertyHeaderElement.current,
        floatingWindowElement.current,
        {strategy: "fixed",
        placement: "left-start",
        middleware: [
          arrow({element: floatingWindowArrowElement.current},
          offset({mainAxis: 8 + 1}))
        ]
      }).then(({x, y, middlewareData}) => {
        Object.assign(floatingWindowElement.current.style, {
          top: `${y - 16}px`,
        });  
        Object.assign(floatingWindowArrowElement.current.style, {
          top: middlewareData.arrow.y + 16 != null ? `${middlewareData.arrow.y + 16}px` : ''
        });
      });
    });
  
    autoUpdate(document.querySelector(".sidebar"), floatingWindowElement.current, () => {
      computePosition(document.querySelector(".sidebar"), floatingWindowElement.current, {strategy: "fixed", placement: "left-start", middleware: [arrow({element: floatingWindowArrowElement.current}), offset(8 + 1)]}).then(({x, y, middlewareData}) => {
        Object.assign(floatingWindowElement.current.style, {
          left: `${x}px`,
        });
  
        Object.assign(floatingWindowArrowElement.current.style, {
          left: middlewareData.arrow.x != null ? `${middlewareData.arrow.x}px` : ''
        });
      });
    });
  
  
    // On Property Header click while minimized toggle popper
    // propertyHeader.current.addEventListener("click", () => {
    //   if (document.querySelector(".sidebar").classList.contains("sidebar--minimized")) {
    //     // Here we want to display small window with more info, on desktop this window appears on hover and on click
    //     if (floatingWindow.current.style.visibility === "hidden")
    //       showFloatingWindow();
    //     else
    //       hideFloatingWindow()
    //   }
    // })
  
    // On outside click hide popper
    document.addEventListener("click", (e) => {
        if (!propertyHeaderElement.current.contains(e.target) && !floatingWindowElement.current.contains(e.target))
          hideFloatingWindow();
    })
  
    new MutationObserver(calculateBodyMaxHeight).observe(propertyBodyElement.current, { attributes: true, childList: true, subtree: true });
    new ResizeObserver(calculateBodyMaxHeight).observe(propertyBodyElement.current);
  }, []);

  return (
    <div
      ref={componentElement}
      class={classNames(
        "property",
        {"property--closed": !isOpen},
        {"property--active": active}
      )}
    >
      <input type="checkbox"/>
      <div ref={propertyHeaderElement} class="property__header" onClick={propertyHeaderOnClick}>
          <img class="property__icon" src={icon} />
          <div class="property__title">
              {title}
          </div>
          <div class="property__status">
              <div class="expand-minimize">
                  <img class="expand-minimize__minus-icon" src={minusIcon} />
                  <img class="expand-minimize__plus-icon" src={plusIcon} />
              </div>
          </div>
      </div>
      <div ref={propertyBodyElement} class="property__body">
        <div class="property__content">
          {children}
        </div>
      </div>
      <div class="property__floating-window">
        <div ref={floatingWindowElement} class={classNames("floating-window", {"floating-window--active": floatingWindowVisible})}>
          {floatingWindow?.title && (
            <div class="floating-window__header">
              {floatingWindow.title}
            </div>
          )}
          {floatingWindow?.items && (
            <div class="floating-window__content">
              {floatingWindow.items.map(item => (
                <div class="floating-window__item">
                  <div class="floating-window__item__name">
                    {item.name}
                  </div>
                  <div class="floating-window__item__value">
                    {item.value}
                  </div>
                </div>
              ))}
            </div>
          )}
          <div ref={floatingWindowArrowElement} class="floating-window__arrow"></div>
        </div>
      </div>
    </div>
  )
}

export default Property