import { h } from 'preact';

import classNames from 'classnames';

// Icons
import checkboxInactive from "../../icons/checkbox_inactive.svg";
import checkboxActive from "../../icons/checkbox_active.svg";

const FieldsetOption = ({
  name,
  value,
  checked,
  iconless,
  onClick,
  onChange
}) => {
  return (
    <label class="checkbox">
      <input
        type="radio"
        class="checkbox__checkbox"
        value={value}
        checked={checked}
        onClick={onClick}
        onChange={onChange}
      />
      {!iconless && (
        <div class="checkbox__icon">
          <img src={checkboxInactive} class="checkbox__inactive-icon"/>
          <img src={checkboxActive} class="checkbox__active-icon"/>
        </div>
      )}
      <div class="checkbox__value">{name}</div>
    </label>
  )
}

const Fieldset = ({
  title,
  options,
  horizontal = false,
  iconless = false,
  checked,
  fullWidth = false,
  onClick,
  onChange,
  disabled
}) => {
  if (!options)
    throw "options not set";  

  return (
    <fieldset
      id={title}
      class={classNames(
        "fieldset",
        {"fieldset--horizontal": horizontal},
        {"fieldset--full-width": fullWidth},
        {"fieldset--disabled": disabled}
      )}
    >
      {title && <span class="fieldset__title">{title}</span>}
      <div class="fieldset__body">
        {options.map((option, i) => {
          return <FieldsetOption
            key={i}
            name={option.name}
            value={option.value}
            iconless={iconless}
            checked={option.value === checked}
            onClick={onClick}
            onChange={onChange}
          />
        })}
      </div>
    </fieldset>
  )
}

export default Fieldset