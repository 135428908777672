import { h } from 'preact';
import { useState, useEffect, useRef } from 'preact/hooks';

import Property from '../property'

import layerOptions from '../../layerOptions';

import Switch from '../switch';
import Fieldset from '../fieldset';

let getCurrentLayerName = () => window.location.hash.substring(1);
const isLayerInProperty = (layerName) => {
  if (!layerName)
    throw "layerName is required"

  if (layerOptions.filter(item => item.property === "SENTINEL").find(item => item.name === layerName))
    return true
  return false;
}

const Sentinel = ({
  open = false
}) => {
  const didNotMount = useRef(true);

  // The values we need for sentinel
  const initializeDisabled = () => {
    let layerName = getCurrentLayerName()
    
    if (isLayerInProperty(layerName))
      return false;
    return true;
  }
  const [disabled, setDisabled] = useState(initializeDisabled());
  
  const initializeMode = () => {  
    let layerName = getCurrentLayerName()

    if(!isLayerInProperty(layerName))
      return {
        selected: "RGB",
      }

    const layer = layerOptions.find(item => item.name === getCurrentLayerName());

    return {
      selected: layer.mode
    }
  }
  const [mode, setMode] = useState(initializeMode());

  const initializeSeason = () => {
    let currentLayerName = getCurrentLayerName()

    let availableOptions = layerOptions.filter(item => item.mode === mode.selected);
    let distinctSeasons = [...new Set(availableOptions.map(item => item.season))];

    if (isLayerInProperty(currentLayerName)) {
      const layer = layerOptions.find(item => item.name === currentLayerName);

      return {
        selected: layer.season,
        available: distinctSeasons.map(item => ({
          name: item,
          value: item
        }))
      }
    }

    return {
      selected: distinctSeasons[0], // We select the first one
      available: distinctSeasons.map(item => ({
        name: item,
        value: item
      }))
    }
  }
  const [season, setSeason] = useState(initializeSeason());

  const initializeYear = () => {
    let layerName = getCurrentLayerName()

    let availableOptions = layerOptions.filter(item => item.mode === mode.selected && item.season === season.selected);
    let years = availableOptions.map(item => item.year);

    if (isLayerInProperty(layerName)) {
      const layer = layerOptions.find(item => item.name === getCurrentLayerName());

      return {
        selected: layer.year,
        available: years.map(item => ({
          name: item,
          value: item
        }))
      }
    }

    return {
      selected: years[0],
      available: years.map(item => ({
        name: item,
        value: item
      }))
    }
  }
  const [year, setYear] = useState(initializeYear());

  const selectorOnClick = (e) => {
    if (disabled)
      setDisabled(false);
  }

  const modeOnChange = (e) => {
    if (!disabled) {
      if (mode.selected === "RGB")
        setMode({...mode, selected: "NIR"});
      else if (mode.selected === "NIR")
        setMode({...mode, selected: "RGB"})
      else
        throw "mode unpredicted state error";
    }
  }

  const seasonOnChange = (e) => {
    if(!disabled) {
      const selectedSeason = e.target.value;
      setSeason({...season, selected: selectedSeason});

      let availableOptions = layerOptions.filter(item => item.mode === mode.selected && item.season === selectedSeason);
      let years = availableOptions.map(item => item.year);

      let selectedYear = years.includes(year.selected) ? year.selected : years[0];

      setYear({
        selected: selectedYear,
        available: years.map(year => ({
          name: year,
          value: year
        }))
      })
    }
  }

  const yearOnChange = (e) => {
    if (disabled) {
      setDisabled(false);
    } else {
      const selectedYear = e.target.value;
      setYear({...year, selected: selectedYear})

      // let availableOptions = layerOptions.filter(item => item.mode === mode.selected && item.year === selectedYear);
      // let seasons = availableOptions.map(item => item.season);

      // setSeason({...season, available: seasons})
    }
  }

  useEffect(() => {
    if (didNotMount.current) {
      didNotMount.current = false;
    } else {
      if (!disabled) {
        let layer = layerOptions.find(item => 
          item.mode === mode.selected &&
          item.season === season.selected &&
          item.year === year.selected
        );
  
        window.setLayer(layer.name)
      }
    }
  }, [mode, season, year, disabled]);

  // Hash change
  let [currentLayerName, setCurrentLayerName] = useState(getCurrentLayerName());
  useEffect(() => {
    if (!isLayerInProperty(currentLayerName))
      setDisabled(true);
  }, [currentLayerName]);
  
  // Subscribe hashchange to currentLayerName state
  const onHashChange = () => setCurrentLayerName(getCurrentLayerName());
  useEffect(() => {
    window.addEventListener("hashchange", onHashChange);
    
    return () => {
      window.removeEventListener("hashchange", onHashChange);
    }
  }, [])

  return (
    <Property
      title="sentinel"
      open={open}
      active={isLayerInProperty(getCurrentLayerName())}
      floatingWindow={{
        title: "SENTINEL",
        items: [
          {
            name: "MODE",
            value: mode.selected
          },
          {
            name: "SEASON",
            value: season.selected
          },
          {
            name: "YEAR",
            value: year.selected
          }
        ]
      }}
    >
      <Switch
        firstValue="RGB"
        secondValue="NIR"
        checked={mode.selected === "NIR"}
        disabled={disabled}
        onClick={selectorOnClick}
        onChange={modeOnChange}
      />
      <Fieldset
        title="Season"
        options={season.available}
        checked={season.selected}
        disabled={disabled}
        onClick={selectorOnClick}
        onChange={seasonOnChange}
      />
      <Fieldset
        title="Year"
        options={year.available}
        checked={year.selected}
        disabled={disabled}
        onClick={selectorOnClick}
        onChange={yearOnChange}
      />
    </Property>
  )
}

export default Sentinel;