// proj4.defs('LAEA', '+proj=laea +lat_0=52 +lon_0=10 +x_0=4321000 +y_0=3210000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs ');
// ol.proj.proj4.register(proj4);

import { h, render, Fragment } from 'preact';

import Sentinel from './assets/js/components/properties/sentinel';
import PredictionsUncertainty from './assets/js/components/properties/predictionsUncertainty';

import layerOptions from "./assets/js/layerOptions";
import waitForElementTransition from 'wait-for-element-transition';

import "./kowalla"

import pointsGeojson from "url:./assets/data/points.geojson";

const mobile = () => window.matchMedia("(max-width: 600px)").matches;

import {computePosition, offset, autoUpdate, arrow} from '@floating-ui/dom';

const gsBaseurl = 'https://kepler.multione.hr/geoserver/soilhr/wms';

const getLegendUrl = function(layerName) {
    let legendLayout = 'vertical';
    // if (mobile()) {legendLayout = 'horizontal';}
    return gsBaseurl + `?REQUEST=GetLegendGraphic&VERSION=1.3.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=soilhr:${layerName}&LEGEND_OPTIONS=layout:${legendLayout};bgColor:0x000000;fontColor:0xbbbbbb;mx:0.1;my:0.1;dx:10`
};  

const getWMSSource = function(layerName) {
    return new ol.source.TileWMS({
        url: gsBaseurl,
        params: {'LAYERS': 'soilhr:'+layerName, 'TILED': true},
        serverType: 'geoserver',
        projection: 'EPSG:3035',
    })
};

const fixMapHeight = () => document.getElementById('map').style.height = window.innerHeight + 'px';
window.onresize = fixMapHeight;
fixMapHeight();

const WMSLayer = new ol.layer.Tile({
    // source: getWMSSource('RGB_2018_q2'),
});

const pointStyle = new ol.style.Style({
    image: new ol.style.Circle({
        radius: 8,
        fill: new ol.style.Fill({color: '#333'}),
        stroke: new ol.style.Stroke({color: '#fff', width: 2})
    }),
});

const pointStyleHighlight = new ol.style.Style({
    image: new ol.style.Circle({
        radius: 12,
        fill: new ol.style.Fill({color: '#333'}),
        stroke: new ol.style.Stroke({color: '#fff', width: 2})
    }),
});

const pointStyleSelected = new ol.style.Style({
    image: new ol.style.Circle({
        radius: 12,
        fill: new ol.style.Fill({color: '#333'}),
        stroke: new ol.style.Stroke({color: '#b4d678', width: 2})
    }),
});

const pointSource = new ol.source.Vector();
const pointLayer = new ol.layer.Vector({
    source: pointSource,
    style: pointStyle,
    rendermode: 'image',
});

const featureOverlay = new ol.layer.Vector({
  source: new ol.source.Vector(),
  style: pointStyleHighlight,
});

const selectedOverlay = new ol.layer.Vector({
  source: new ol.source.Vector(),
  style: pointStyleSelected,
});

const [cx, cy] = ol.proj.fromLonLat([16.5, 45.]);
const extent_radius = 5e5
const extent = [
    cx - extent_radius,
    cy - extent_radius,
    cx + extent_radius,
    cy + extent_radius,
]

const view = new ol.View({
    // center: ol.proj.fromLonLat([15., 45.]),
    center: [1753073.86898, 5729877.37109],
    zoom: 11,
    minZoom: 7,
    extent: extent,
    // projection: ol.proj.get('LAEA'), // tilegrid se shifta ako promijenim projekciju...
});

const map = new ol.Map({
    target: 'map',
    layers: [
        new ol.layer.Tile({
            source: new ol.source.Stamen({layer: 'toner-background'}),
            opacity: .25,
        }),

        WMSLayer,

        new ol.layer.VectorTile({
            source: new ol.source.VectorTile({
                url: `https://kepler.multione.hr/basemap/{z}/{x}/{y}.pbf`,
                format: new ol.format.MVT(),
            }),
            style: style_basemap,
            rendermode: 'image',
        }),

        pointLayer,
        featureOverlay,
        selectedOverlay,
    ],
    view: view,
    controls: [
        // new ol.control.Attribution(),
    ],
    interactions: ol.interaction.defaults({
        altShiftDragRotate: false,
        pinchRotate: false,
    }),
});

// const info = document.getElementById('feature-info');

// let highlight;
// let highlight_persist;

const featureInfoKeys = [
    'site_key',
    'source_db',
    'site_obsdate',
    'dbr',
    'oc',
    'sand_tot_psa',
    'silt_tot_psa',
    'clay_tot_psa',
    'caco3',
    'ph_h2o',
    'ph_kcl',
    'wpg2',
    'p_mehlich3',
    'k_mehlich3',
    'n_tot_ncs',
    'ec_satp',
    'db_od',
    'ca_mehlich3',
    'mg_mehlich3',
]


let ShrinkLeftSidebar = () => {
    let leftSidebar = document.querySelector(".left-sidebar");
    let toggler = document.querySelector("#legend-toggler");

    leftSidebar.classList.remove("left-sidebar--extended");
    leftSidebar.classList.add("left-sidebar--shrank");
    toggler.classList.remove("legend-toggler--active");
}
let ExpandLeftSidebar = () => {
    let leftSidebar = document.querySelector(".left-sidebar");
    let toggler = document.querySelector("#legend-toggler");

    leftSidebar.classList.remove("left-sidebar--shrank");
    leftSidebar.classList.add("left-sidebar--extended");
    toggler.classList.add("legend-toggler--active");
}
ShrinkLeftSidebar();

let ToggleLeftSidebar = () => {
    let leftSidebar = document.querySelector(".left-sidebar");

    if (leftSidebar.classList.contains("left-sidebar--extended"))
        ShrinkLeftSidebar();
    else
        ExpandLeftSidebar();
}
window.ToggleLeftSidebar = ToggleLeftSidebar;

const round = (num, dec=1) => Math.round((num + Number.EPSILON) * 10**dec) / 10**dec;

let selectedPoint;
let hoveredPoints = [];
let EnlargePoint = point => {
    let source = featureOverlay.getSource();
    if (!source.getFeatures().includes(point))
        source.addFeature(point);
}
let UnEnlargePoint = point => featureOverlay.getSource().removeFeature(point);
let ColorPoint = point => selectedOverlay.getSource().addFeature(point);
let UnColorPoint = point => selectedOverlay.getSource().removeFeature(point);

let ShowPointInfoWindow = () => {
    let pointInfoWindow = document.querySelector("#point-info-window");

    pointInfoWindow.classList.add("point-info-window--active");
}
let HidePointInfoWindowAndDeselectPoint = () => {
    let pointInfoWindow = document.querySelector("#point-info-window");

    pointInfoWindow.classList.remove("point-info-window--active");

    UnEnlargePoint(selectedPoint);
    UnColorPoint(selectedPoint);
    selectedPoint = undefined;
}

window.HidePointInfoWindowAndDeselectPoint = HidePointInfoWindowAndDeselectPoint;

let ShowContentPointInfoWindow = () => {
    let pointInfoWindow = document.querySelector("#point-info-window");
    let pointInfoToggle = pointInfoWindow.querySelector(".point-info-toggle");
    let pointInfoWindowContent = pointInfoWindow.querySelector(".point-info-window__content");

    pointInfoWindowContent.classList.remove("point-info-window__content--hidden");
    pointInfoToggle.classList.remove("point-info-toggle--active");
}

let HideContentPointInfoWindow = () => {
    let pointInfoWindow = document.querySelector("#point-info-window");
    let pointInfoToggle = pointInfoWindow.querySelector(".point-info-toggle");
    let pointInfoWindowContent = pointInfoWindow.querySelector(".point-info-window__content");

    pointInfoWindowContent.classList.add("point-info-window__content--hidden");
    pointInfoToggle.classList.add("point-info-toggle--active");
}

let ToggleContentPointInfoWindow = () => {
    let pointInfoWindow = document.querySelector("#point-info-window");
    let pointInfoWindowContent = pointInfoWindow.querySelector(".point-info-window__content");

    if (pointInfoWindowContent.classList.contains("point-info-window__content--hidden"))
        ShowContentPointInfoWindow();
    else
        HideContentPointInfoWindow();
}
window.ToggleContentPointInfoWindow = ToggleContentPointInfoWindow;

let LoadPointInfoWindow = () => {
    let leftSidebar = document.querySelector(".left-sidebar");
    let pointInfoWindow = document.querySelector("#point-info-window");

    let UpdatePointInfoWindowPosition = () => {
        computePosition(
            leftSidebar,
            pointInfoWindow,
            {
                strategy: "fixed",
                placement: "right-end",
                middleware: [offset({mainAxis: 16, crossAxis: -16})]
            }
            ).then(({x, y}) => {
                // Object.assign(pointInfoWindow.style, {top: `${y}px`});
                // Object.assign(pointInfoWindow.style, {bottom: `${window.screen.height - pointInfoWindow.clientHeight}px`});
                Object.assign(pointInfoWindow.style, {bottom: `calc(100% - ${pointInfoWindow.clientHeight + y}px)`});

                Object.assign(pointInfoWindow.style, {left: `${x}px`});
        })
    }
    // UpdatePointInfoWindowPosition();

    // autoUpdate(leftSidebar, pointInfoWindow, UpdatePointInfoWindowPosition);

    // new MutationObserver(() => {
    //     waitForElementTransition(leftSidebar).then(UpdatePointInfoWindowPosition)
    // }).observe(leftSidebar, { attributes: true, childList: true })
}
// LoadPointInfoWindow();

let FeatureIsPoint = feature => feature && feature.getProperties()?.fid;

const onPointClick = (pixel) => {
    let point = map.forEachFeatureAtPixel(pixel, feature => feature);

    // If the selected coordinates are a point (we identify points by having a fid property)
    if(FeatureIsPoint(point)) {
        // If we already have a selected point
        if (selectedPoint) {
            // Unselect it
            UnEnlargePoint(selectedPoint);
            UnColorPoint(selectedPoint);
            selectedPoint = undefined;
        } else
            ShowContentPointInfoWindow();
        
        EnlargePoint(point);
        ColorPoint(point);
        selectedPoint = point;

        if (hoveredPoints.includes(point))
            hoveredPoints.splice(hoveredPoints.indexOf(point), 1);

        // Update data display inside point window
        let props = point.getProperties();
        let pointInfoWindow = document.querySelector("#point-info-window");
        let pointInfoWindowDescription = pointInfoWindow.querySelector(".point-info-window__description");
        let pointInfoWindowContent = pointInfoWindow.querySelector(".point-info-window__content");
        
        pointInfoWindowDescription.innerHTML = `Negative topographic openess value: ${'123'}`

        pointInfoWindowContent.innerHTML = '';
        for (let key in props)
            if (props[key] && featureInfoKeys.includes(key)) {
                // If the value is a number, round it
                let value = isNaN(props[key]) ? props[key] : round(props[key]);
                pointInfoWindowContent.insertAdjacentHTML("beforeend", `<span>${key}</span><span>${value}</span>`)
            }

        ShowPointInfoWindow();
    } else {
        // If we already have a selected point
        if (selectedPoint) {
            // Hide Point Info Window and Unselect point
            HidePointInfoWindowAndDeselectPoint()
        }
    }
}

const onPointHover = (pixel) => {
    let point = map.forEachFeatureAtPixel(pixel, feature => feature);

    // 2 things can happen: we hover a point and we don't hover a point
    // We hovered a point:
    if (FeatureIsPoint(point) && point != selectedPoint) {
        // If the point is not marked as hovered
        if (!hoveredPoints.includes(point)) {
            // Unhover all other hovered points
            if (hoveredPoints.length !== 0) {
                let hoveredPoint = hoveredPoints.pop();
                UnEnlargePoint(hoveredPoint);
            }

            // Hover the point
            hoveredPoints.push(point);
            EnlargePoint(point);
        }
    }
    // We didn't hover a point
    else {
        // If we have a point marked as hovered, unmark it
        while(hoveredPoints.length !== 0) {
            let hoveredPoint = hoveredPoints.pop();
            UnEnlargePoint(hoveredPoint);
        }
    }
}

const displayFeatureInfo = function(pixel, click=false) {

    // let feature = map.forEachFeatureAtPixel(pixel, function(feature) {
    //     return feature;
    // });

    // if (click) {
    //     selectedOverlay.getSource().clear();
    //     if (feature) {
    //         let props = feature.getProperties();
    //         if (props.fid) {
    //             let infoText = '<th><h1>Point info</h1></th>';
    //             for (const key in props) {
    //                 if (featureInfoKeys.includes(key) && props.hasOwnProperty(key)) {
    //                     let val = props[key];
    //                     if (val !== null) {
    //                         let val_disp = round(val);
    //                         if (isNaN(val_disp)) val_disp = val;
    //                         infoText += `<tr><td>${key}:</td><td>${val_disp}</td></tr>`;
    //                     }
    //                 }
    //             }
    //             info.innerHTML = infoText;
    //             selectedOverlay.getSource().addFeature(feature);
    //         }
    //     } else {
    //         info.innerHTML = '';
    //     }
    // }

    // if (feature !== highlight) {
    //     if (highlight) {
    //         try {
    //             featureOverlay.getSource().removeFeature(highlight);
    //         } catch {}
    //     }
    //     if (feature) {
    //         try {
    //             featureOverlay.getSource().addFeature(feature);
    //         } catch {}
    //     }
    //     highlight = feature;

    //     // console.log(highlight);
    //     // console.log(feature);
    // }

    // if (click && (feature !== highlight_persist)) {
    //     if (highlight_persist) {
    //         try {
    //             featureOverlay.getSource().removeFeature(highlight_persist);
    //         } catch {}
    //     }
    //     if (feature) {
    //         try {
    //             featureOverlay.getSource().addFeature(feature);
    //         } catch {}
    //     }
    //     highlight_persist = feature;
    // }

};

map.on('pointermove', function(evt) {
    if (evt.dragging) {
        return;
    }
    let pixel = map.getEventPixel(evt.originalEvent);
    // displayFeatureInfo(pixel);
    onPointHover(pixel);
});

// const currentLayerValue = document.getElementById('current-layer-value');

// This function updates GRAY_INDEX value displayed to the user when he clicks somewhere on the map
map.on('singleclick', function (evt) {
    var viewResolution = /** @type {number} */ (view.getResolution());
    var url = WMSLayer.getSource().getFeatureInfoUrl(
        evt.coordinate,
        viewResolution,
        'EPSG:3857',
        {'INFO_FORMAT': 'application/json'},
    );
    if (url) {
        fetch(url)
            .then(function (response) { return response.json(); })
            .then(function (data) {
                const valueElement = document.querySelector("#value");
                let val = data.features[0].properties.GRAY_INDEX;
                if (val !== undefined) {
                    val = round(val, 2);
                    valueElement.innerHTML = `<b>VALUE</b><br/>${val}`
                }
            });
    }

    console.log(url);
});

map.on('click', function(evt) {
    displayFeatureInfo(evt.pixel, true);
    onPointClick(evt.pixel);
});

// info.parentElement.onclick = function(evt) {
//     displayFeatureInfo([null, null], true);
// };

// const layerPicker = document.getElementById('layers-wrapper');

const layerGroups = {
    sentinel2: [
        'RGB_2018_q2',
        'RGB_2018_q3',
        'RGB_2018_q4',
        'RGB_2019_q1',
        'RGB_2019_q2',
        'RGB_2019_q3',
        'RGB_2019_q4',
        's2l2a_B08_2018_q2_P25',
        's2l2a_B08_2018_q3_P25',
        's2l2a_B08_2018_q4_P25',
        's2l2a_B08_2019_q1_P25',
        's2l2a_B08_2019_q2_P25',
        's2l2a_B08_2019_q3_P25',
        's2l2a_B08_2019_q4_P25',
    ],
    // climate: [
    //     'clm_lst_mod11a2.annual.day_m_1km_s0..0cm_2000..2017_v1.0',
    //     'clm_precipitation_imerge.annual_m_1km_s0..0cm_2014..2018_v0.1',
    // ],
    // terrain: [
    //     'hillshade',
    //     'dem_v2_openn',
    //     'dem_v2_openp',
    //     'dem_v2_slope',
    //     'dem_v2_flow_accum_masked',
    // ],
    predictions: [
        'clay_tot_psa_norm_v15',
        'db_od_v15',
        'ph_h2o_v15',
        'oc_v16',
        'ph_kcl_v15',
        'sand_tot_psa_norm_v15',
        'silt_tot_psa_norm_v15',
        // 'dbr_v15',
        'k_mehlich3_v15',
        'p_mehlich3_v15',
        'ca_mehlich3_v15',
        'mg_mehlich3_v15',
        'caco3_v15',
        'wpg2_v15',
        'ec_satp_v15',
        'cec_sum_v15',
        'wrb_rsg_v15',
        'texture_v15',
    ],
    uncertainty: [
        'clay_tot_psa_uncertainty_v15',
        'db_od_uncertainty_v15',
        'ph_h2o_uncertainty_v15',
        'oc_uncertainty_v16',
        'ph_kcl_uncertainty_v15',
        'sand_tot_psa_uncertainty_v15',
        'silt_tot_psa_uncertainty_v15',
        // 'dbr_uncertainty_v15',
        'k_mehlich3_uncertainty_v15',
        'p_mehlich3_uncertainty_v15',
        'ca_mehlich3_uncertainty_v15',
        'mg_mehlich3_uncertainty_v15',
        'caco3_uncertainty_v15',
        'wpg2_uncertainty_v15',
        'ec_satp_uncertainty_v15',
        'cec_sum_uncertainty_v15',
        'wrb_rsg_prediction_entropy_v15',
    ],
};

const layerAlias = {
    // RGB_2018_q2: 'RGB_2018_spring',
    // RGB_2018_q3: 'RGB_2018_summer',
    // RGB_2018_q4: 'RGB_2018_autumn',
    // RGB_2019_q1: 'RGB_2019_winter',
    // RGB_2019_q2: 'RGB_2019_spring',
    // RGB_2019_q3: 'RGB_2019_summer',
    // RGB_2019_q4: 'RGB_2019_autumn',
    // s2l2a_B08_2018_q2_P25: 'NIR_2018_spring',
    // s2l2a_B08_2018_q3_P25: 'NIR_2018_summer',
    // s2l2a_B08_2018_q4_P25: 'NIR_2018_autumn',
    // s2l2a_B08_2019_q1_P25: 'NIR_2019_winter',
    // s2l2a_B08_2019_q2_P25: 'NIR_2019_spring',
    // s2l2a_B08_2019_q3_P25: 'NIR_2019_summer',
    // s2l2a_B08_2019_q4_P25: 'NIR_2019_autumn',
    RGB_2018_spring: 'RGB_2018_q2',
    RGB_2018_summer: 'RGB_2018_q3',
    RGB_2018_autumn: 'RGB_2018_q4',
    RGB_2019_winter: 'RGB_2019_q1',
    RGB_2019_spring: 'RGB_2019_q2',
    RGB_2019_summer: 'RGB_2019_q3',
    RGB_2019_autumn: 'RGB_2019_q4',
    NIR_2018_spring: 's2l2a_B08_2018_q2_P25',
    NIR_2018_summer: 's2l2a_B08_2018_q3_P25',
    NIR_2018_autumn: 's2l2a_B08_2018_q4_P25',
    NIR_2019_winter: 's2l2a_B08_2019_q1_P25',
    NIR_2019_spring: 's2l2a_B08_2019_q2_P25',
    NIR_2019_summer: 's2l2a_B08_2019_q3_P25',
    NIR_2019_autumn: 's2l2a_B08_2019_q4_P25',
    'clm_lst_mod11a2.annual.day_m_1km_s0..0cm_2000..2017_v1.0': 'LST annual mean 2000-2017',
    'clm_precipitation_imerge.annual_m_1km_s0..0cm_2014..2018_v0.1': 'Precipitation annual mean 2014-2018',
    hillshade: 'DEM (hillshade)',
    dem_v2_openn: 'Negative topographic openness',
    dem_v2_openp: 'Positive topographic openness',
    dem_v2_slope: 'Slope',
    dem_v2_flow_accum_masked: 'Flow accumulation',
    clay_tot_psa_norm_v15: 'clay_tot_psa',
    clay_tot_psa_uncertainty_v15: 'clay_tot_psa_uncertainty',
    db_od_v15: 'db_od',
    db_od_uncertainty_v15: 'db_od_uncertainty',
    ph_h2o_v15: 'ph_h2o',
    ph_h2o_uncertainty_v15: 'ph_h2o_uncertainty',
    oc_v16: 'oc',
    oc_uncertainty_v16: 'oc_uncertainty',
    ph_kcl_v15: 'ph_kcl',
    ph_kcl_uncertainty_v15: 'ph_kcl_uncertainty',
    sand_tot_psa_norm_v15: 'sand_tot_psa',
    sand_tot_psa_uncertainty_v15: 'sand_tot_psa_uncertainty',
    silt_tot_psa_norm_v15: 'silt_tot_psa',
    silt_tot_psa_uncertainty_v15: 'silt_tot_psa_uncertainty',
    // dbr_v15: 'dbr',
    // dbr_uncertainty_v15: 'dbr_uncertainty',
    k_mehlich3_v15: 'k_mehlich3',
    k_mehlich3_uncertainty_v15: 'k_mehlich3_uncertainty',
    p_mehlich3_v15: 'p_mehlich3',
    p_mehlich3_uncertainty_v15: 'p_mehlich3_uncertainty',
    ca_mehlich3_v15: 'ca_mehlich3',
    ca_mehlich3_uncertainty_v15: 'ca_mehlich3_uncertainty',
    mg_mehlich3_v15: 'mg_mehlich3',
    mg_mehlich3_uncertainty_v15: 'mg_mehlich3_uncertainty',
    caco3_v15: 'caco3',
    caco3_uncertainty_v15: 'caco3_uncertainty',
    wpg2_v15: 'wpg2',
    wpg2_uncertainty_v15: 'wpg2_uncertainty',
    ec_satp_v15: 'ec_satp',
    ec_satp_uncertainty_v15: 'ec_satp_uncertainty',
    cec_sum_v15: 'cec_sum',
    cec_sum_uncertainty_v15: 'cec_sum_uncertainty',
    texture_v15: 'texture_class',
    wrb_rsg_v15: 'wrb_rsg',
    wrb_rsg_prediction_entropy_v15: 'wrb_rsg_uncertainty',
};

const currentLayerTitle = document.getElementById('current-layer');
const currentLayerLegend = document.getElementById('layer-legend');

const setLayer = (layerName) => {
    let topNav = document.querySelector("#top-nav");
    let topNavCurrentLayer = topNav.querySelector("#top-nav__current-layer");

    let currentLayerProperty = layerOptions.filter(item => item.name === layerName)[0].property;
    topNavCurrentLayer.innerHTML = currentLayerProperty;

    WMSLayer.setSource(getWMSSource(layerName));
    window.location.hash = '#' + layerName;
    currentLayerLegend.src = getLegendUrl(layerName);

    // Hide left sidebar if no legend
    const isRGB = layerOptions.find(layer => layer.name === layerName && layer.mode === "RGB") !== undefined;

    if (isRGB) {
        document.querySelector(".left-sidebar").classList.add("left-sidebar--hidden")
    } else {
        document.querySelector(".left-sidebar").classList.remove("left-sidebar--hidden");
        document.querySelector(".left-sidebar").classList.remove("left-sidebar--extended");
        document.querySelector("#legend-toggler").classList.remove("legend-toggler--active");
    }

};
window.setLayer = setLayer;

// Points API
let ArePointsVisible = () => pointLayer.getVisible();

let ShowPoints = () => pointLayer.setVisible(true);
window.ShowPoints = ShowPoints;

let HidePoints = () => pointLayer.setVisible(false);
window.HidePoints = HidePoints;
HidePoints();

let SetOpacityPoints = (value) => pointLayer.setOpacity(value);
window.SetOpacityPoints = SetOpacityPoints;

// Default layer
if (layerOptions.filter(item => item.name === window.location.hash.split("#")[1]).length === 0) {
    setLayer(layerOptions.filter(item => item?.mode === "RGB")[0].name);
} else {
    setLayer(window.location.hash.split("#")[1]);
}

fetch(pointsGeojson)
    .then(resp => resp.json())
        .then(data => {
            let format = new ol.format.GeoJSON();
            pointSource.addFeatures(format.readFeatures(data));
            // pointLayer.setSource(new ol.source.Vector({
            //     features: format.readFeatures(data),
            // }));
        }
);

let initializeTopNav = () => {
    let leftSidebar = document.querySelector(".left-sidebar");
    let toggler = document.querySelector("#opacity-toggler");
    let topNav = document.querySelector(".top-nav");
    // let topNav = topNav.querySelector(".top-nav__window");
    let topNavToggleVisibility = topNav.querySelector(".point-visibility");
    let topNavProperties = topNav.querySelectorAll(".top-nav__property");
    let modals = topNav.querySelectorAll(".top-nav__modal");

    // Initialize pointLayer Toggler state
    if (pointLayer.getVisible())
        topNavToggleVisibility.classList.add("point-visibility--active");
    else
        topNavToggleVisibility.classList.remove("point-visibility--active");

    // Add onCLick event to pointLayer Toggler
    let ShowPoints = () => {
        pointLayer.setVisible(true);
        topNavToggleVisibility.classList.add("point-visibility--active");
    }
    let HidePoints = () => {
        pointLayer.setVisible(false);
        topNavToggleVisibility.classList.remove("point-visibility--active");
    }

    topNavToggleVisibility.addEventListener("click", (e) => {
        if(pointLayer.getVisible()) {
            HidePoints();
        } else {
            ShowPoints();
        }
    })

    let UpdateTopNavPosition = () => {
        computePosition(
            toggler,
            topNav,
            {
                strategy: "fixed",
                placement: "bottom-start",
            }
            ).then(({x, y}) => {
                Object.assign(topNav.style, {top: `${y}px`});
                Object.assign(topNav.style, {left: `${x}px`});
        })
    }
    UpdateTopNavPosition();

    toggler.addEventListener("click", (e) => {
        let leftSidebar = document.querySelector(".left-sidebar");

        let IsTopNavActive = () => toggler.classList.contains("opacity-toggler--active");

        let ActivateTopNav = () => {
            toggler.classList.add("opacity-toggler--active");
            topNav.classList.add("top-nav--active");
        }

        let DisableTopNav = () => {
            toggler.classList.remove("opacity-toggler--active");
            topNav.classList.remove("top-nav--active");
        }

        let IsLeftSidebarExtended = () => leftSidebar.classList.contains("left-sidebar--extended");

        let ShrinkLeftSidebar = () => {
            leftSidebar.classList.remove("left-sidebar--extended");
            leftSidebar.classList.add("left-sidebar--shrank");
        }

        if (IsTopNavActive()) {
            DisableTopNav();
        } else {
            if (IsLeftSidebarExtended()) {
                ShrinkLeftSidebar();

                waitForElementTransition(leftSidebar).then(() => {
                    setTimeout(() => {
                        UpdateTopNavPosition();
                        ActivateTopNav();
                    }, 10)
                })
            } else {
                UpdateTopNavPosition();
                ActivateTopNav();
            }
        }
    })

    // Detect click outside
    document.addEventListener("click", (e) => {
        if(!toggler.contains(e.target) && !topNav.contains(e.target)) {
            toggler.classList.remove("opacity-toggler--active");
            topNav.classList.remove("top-nav--active");
        }
    })

    // For Desktop
    modals.forEach(modal => {
        autoUpdate(topNav, modal, () => {
            computePosition(
                topNav,
                modal,
                {
                    strategy: "fixed",
                    placement: "bottom",
                    middleware: [
                        offset({mainAxis: 4})
                    ]
                }
                ).then(({x, y}) => {
                    Object.assign(modal.style, {top: `${y}px`});
                    Object.assign(modal.style, {left: `${x}px`});
            })
        })
    })

    topNavProperties.forEach((property, index) => {
        let opacityToggler = property.querySelector(".opacity");
        let modal = property.querySelector(".top-nav__modal");

        const ModalActiveClass = "top-nav__modal--active";
        let ShowModal = () => modal.classList.add(ModalActiveClass);
        let HideModal = () => modal.classList.remove(ModalActiveClass);
        let ToggleModal = () => modal.classList.contains(ModalActiveClass) ? HideModal() : ShowModal();

        let HideAllModals = () => topNavProperties.forEach(property => property.querySelector(".top-nav__modal").classList.remove(ModalActiveClass))

        opacityToggler.addEventListener("click", () => {
            if (!modal.classList.contains(ModalActiveClass))
                HideAllModals();
            ToggleModal();
        });

        // Hide on outside click
        document.addEventListener("click", (e) => {
            if (!property.contains(e.target))
                HideModal();
        })
    })

    // Range slider
    topNavProperties.forEach((property, index) => {
        let opacitySlider = property.querySelector("input[type=range]");
        let opacityOption = property.querySelector(".opacity");

        // point layer
        if (index == 0) {
            // Slider
            opacitySlider.addEventListener("input", (e) => {
                pointLayer.setOpacity(e.currentTarget.value / 100);
                opacityOption.innerHTML = `${e.currentTarget.value}%`;
            });

            opacitySlider.value = pointLayer.getOpacity() * 100;
            opacityOption.innerHTML = `${pointLayer.getOpacity() * 100}%`;
        } // map layer 
        else if (index == 1) {
            opacitySlider.addEventListener("input", (e) => {
                WMSLayer.setOpacity(e.currentTarget.value / 100);
                opacityOption.innerHTML = `${e.currentTarget.value}%`;
            });

            opacitySlider.value = WMSLayer.getOpacity() * 100;
            opacityOption.innerHTML = `${WMSLayer.getOpacity() * 100}%`;
        }
    })

    // 
}
initializeTopNav();

let initializeDesktopCopyright = () => {
    let desktopCopyrightElement = document.querySelector(".desktop-copyright");
    let desktopCopyrightToggler = desktopCopyrightElement.querySelector(".information-icon");
    let desktopCopyrightContent = desktopCopyrightElement.querySelector(".desktop-copyright__content");
    let desktopCopyrightContentContainer = desktopCopyrightElement.querySelector(".desktop-copyright__content__container");

    setTimeout(() => {
        desktopCopyrightContent.style.maxWidth = `${desktopCopyrightContentContainer.scrollWidth + desktopCopyrightContentContainer.offsetWidth}px`;
    }, 500)

    let CloseCopyright = () => desktopCopyrightElement.classList.add("desktop-copyright--closed");
    let OpenCopyright = () => desktopCopyrightElement.classList.remove("desktop-copyright--closed");

    let ToggleCopyright = () => 
        desktopCopyrightElement.classList.contains("desktop-copyright--closed")
            ? OpenCopyright()
            : CloseCopyright()

    desktopCopyrightToggler.addEventListener("click", ToggleCopyright);

    document.addEventListener("click", (e) => {
        if (!desktopCopyrightElement.contains(e.target))
            CloseCopyright()
    })
}
initializeDesktopCopyright();

if (!mobile()) document.querySelector(".sidebar").classList.remove("sidebar--minimized");

document.querySelector(".sidebar__content").innerHTML = '';
render(
    <Fragment>
        <Sentinel open={true}/>
        <PredictionsUncertainty/>
    </Fragment>,
    document.querySelector(".sidebar__content")
);