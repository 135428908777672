import { h } from 'preact';
import { useState, useEffect, useRef } from 'preact/hooks';

import Property from '../property'

import layerOptions from '../../layerOptions';

import Fieldset from '../fieldset';

import predictionsUncertaintylIcon from "../../../icons/properties/predictions.svg";

let getCurrentLayerName = () => window.location.hash.substring(1);
const isLayerInProperty = (layerName) => {
  if (!layerName)
    throw "layerName is required"

  if (layerOptions.filter(item => ["PREDICTIONS", "UNCERTAINTY"].includes(item.property)).find(item => item.name === layerName))
    return true
  return false;
}

const PredictionsUncertainty = ({
  open = false
}) => {
  const initializeDisplayFieldset = () => {
    let layer = layerOptions.find(layer => layer.name === getCurrentLayerName());

    return {
      selected: isLayerInProperty(getCurrentLayerName()) ? layer.property : "PREDICTIONS",
      available: [
        "PREDICTIONS",
        "UNCERTAINTY"
      ].map(property => ({
        name: property,
        value: property
      })),
      disabled: isLayerInProperty(getCurrentLayerName()) === false
    }
  }
  const [displayFieldset, setDisplayFieldset] = useState(initializeDisplayFieldset());

  const initializePropertyFieldset = () => {
    const availableLayers = layerOptions.filter(item => item.property === displayFieldset.selected).map(item => item);
    let currentLayerName = layerOptions.find(item => item.name === getCurrentLayerName()).name;

    return {
      selected: currentLayerName,
      available: availableLayers.map(layer => ({
        name: layer.alias,
        value: layer.name
      }))
    }
  }
  const [propertyFieldset, setPropertyFieldset] = useState(initializePropertyFieldset())

  const displayOnClick = (e) => {
    if(displayFieldset.disabled) {
      setDisplayFieldset({...displayFieldset,
        disabled: false});

      if (e.target.value === displayFieldset.selected) {
        const availableLayers = layerOptions.filter(item => item.property === e.target.value);

        setPropertyFieldset({
          ...propertyFieldset,
          available: availableLayers.map(layer => ({
            name: layer.alias,
            value: layer.name
          })),
          selected: availableLayers[0].name
        });
        window.setLayer(availableLayers[0].name)
      }
    }
  }

  const displayOnChange = (e) => {
    setDisplayFieldset({...displayFieldset, selected: e.target.value });

    const availableLayers = layerOptions.filter(item => item.property === e.target.value);

    // We want to select a new layer that is the equivalent
    // for the other display of the current one
    if (isLayerInProperty(getCurrentLayerName())) {
      const currentLayer = layerOptions.find(layer => layer.name === getCurrentLayerName());
      const targetLayer = availableLayers.find(layer => layer.group == currentLayer.group)?.name || availableLayers[0].name;

      setPropertyFieldset({
        ...propertyFieldset,
        available: availableLayers.map(layer => ({
          name: layer.alias,
          value: layer.name
        })),
        selected: targetLayer,
      });

      window.setLayer(
        targetLayer
      );
    } else {
      setPropertyFieldset({
        ...propertyFieldset,
        available: availableLayers.map(layer => ({
          name: layer.alias,
          value: layer.name
        })),
        selected: availableLayers[0].name,
      });
      window.setLayer(availableLayers[0].name)
    }
  }
  const propertyOnChange = (e) => {
    const layerName = e.target.value;

    window.setLayer(layerName)
    setPropertyFieldset({
      ...propertyFieldset,
      selected: layerName,
      disabled: isLayerInProperty(getCurrentLayerName()) === false
    });
  }

  // Hash change
  let [currentLayerName, setCurrentLayerName] = useState(getCurrentLayerName());
  useEffect(() => { 
    if (!isLayerInProperty(currentLayerName)) {
      setPropertyFieldset({...propertyFieldset, selected: ""});
      setDisplayFieldset({...displayFieldset, disabled: isLayerInProperty(getCurrentLayerName()) === false})
    } else {
      setDisplayFieldset({...displayFieldset, disabled: isLayerInProperty(getCurrentLayerName()) === false})
    }
  }, [currentLayerName]);
  
  // Subscribe hashchange to currentLayerName state
  const onHashChange = () => setCurrentLayerName(getCurrentLayerName());
  useEffect(() => {
    window.addEventListener("hashchange", onHashChange);
    
    return () => {
      window.removeEventListener("hashchange", onHashChange);
    }
  }, [])

  return (
    <Property
      title="PREDICTIONS / UNCERTAINTY"
      icon={predictionsUncertaintylIcon}
      open={open}
      active={isLayerInProperty(getCurrentLayerName())}
      floatingWindow={{
        title: "PREDICTIONS / UNCERTAINTY",
        items: [
          {
            name: "DISPLAY",
            value: displayFieldset.selected
          },
          {
            name: "PROPERTY",
            value: layerOptions.find(layer => layer.name === propertyFieldset.selected)?.group
          },
        ]
      }}
    >
      <Fieldset
        title="DISPLAY"
        horizontal

        checked={displayFieldset.selected}
        options={displayFieldset.available}

        onChange={displayOnChange}
        onClick={displayOnClick}
        disabled={displayFieldset?.disabled}
      />
      <Fieldset
        title="PROPERTY"
        iconless  

        checked={propertyFieldset.selected}
        options={propertyFieldset.available}

        onChange={propertyOnChange}
      />
    </Property>
  )
}

export default PredictionsUncertainty